import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material";
import { lightTheme } from "@unipile/web-ui";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import ExpirationDialog from "./components/common/ExpirationDialog";
import NotFoundPage from "./pages/404";
import GoogleOAuth from "./pages/GoogleOAuth";
import Home from "./pages/Home";
import OutlookOauth from "./pages/OutlookOauth";
import { Dispatch, SetStateAction, createContext, useState } from "react";
import { AccountType, ReconnectValues } from "./type";
import { Common } from "@unipile/types";
import { SyncLimit } from "./api/home";

export type AppContextType = {
  selectedProvider?: AccountType | undefined;
  setSelectedProvider: Dispatch<SetStateAction<AccountType | undefined>>;
  reconnectValues?: ReconnectValues | undefined;
  setReconnectValues: Dispatch<SetStateAction<ReconnectValues | undefined>>;
  defaultSyncLimit?: SyncLimit | undefined;
  setDefaultSyncLimit: Dispatch<SetStateAction<SyncLimit | undefined>>;
};

export const AppContext = createContext<AppContextType | null>(null);

export const AppContextProvider: React.FC = ({ children }) => {
  const [selectedProvider, setSelectedProvider] = useState<AccountType>();
  const [reconnectValues, setReconnectValues] = useState<ReconnectValues>();
  const [defaultSyncLimit, setDefaultSyncLimit] = useState<SyncLimit>();

  return (
    <AppContext.Provider
      value={{
        defaultSyncLimit,
        reconnectValues,
        selectedProvider,
        setDefaultSyncLimit,
        setReconnectValues,
        setSelectedProvider,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <AppContextProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/:encodedUrl" element={<Home />} />
            <Route path="/googleOauth" element={<GoogleOAuth />} />
            <Route path="/microsoftOauth" element={<OutlookOauth />} />
          </Route>
        </Routes>
      </AppContextProvider>

      <ExpirationDialog />
    </ThemeProvider>
  );
}

export default App;

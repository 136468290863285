import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

type LoginOptionsProps = {
  label: string;
};

export const LoginOptions: React.FC<LoginOptionsProps> = ({
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack>
      <Button
        sx={{ width: "fit-content" }}
        onClick={() => setIsOpen(!isOpen)}
        startIcon={isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {label}
      </Button>
      <Box sx={{ display: isOpen ? "block" : "none" }}>{children}</Box>
    </Stack>
  );
};

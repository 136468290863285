import { Common } from "libs/types/src";
import { api } from ".";
import { AccountType, TokenPayload } from "../type";

/**
 * ISO 8601 UTC Datetime with milliseconds in extended format :
 *
 * YYYY-MM-DDTHH:mm:ss.SSSZ
 *
 * @note Opaque type.
 */
declare const validUTCDateTimeMs: unique symbol;
export type UTCDateTimeMs = string & { [validUTCDateTimeMs]: true };

export interface SyncLimit {
  MAILING?: "NO_HISTORY_SYNC";
  /** @todo Ask why only number was expected here ?   */
  MESSAGING?: Common.SyncLimit<number | UTCDateTimeMs>;
}

interface ReturnedDataCreate {
  type: "create";
  providers: AccountType[];
  successUrl: string;
  failureUrl: string;
  syncLimit?: SyncLimit;
}

interface ReturnedDataReconnect {
  type: "reconnect";
  accountId: string;
  accountType: AccountType;
  successUrl: string;
  failureUrl: string;
  accountInfo?: string;
  syncLimit?: SyncLimit;
}

export const getTokenPayload = async (): Promise<ReturnedDataCreate | ReturnedDataReconnect> => {
  const { data }: { data: TokenPayload } = await api.get(`/hosted/accounts/auth_payload`);

  return {
    ...(data.type === "create"
      ? {
          type: "create",
          providers: data.providers,
          successUrl: data.success_redirect_url || "",
          failureUrl: data.failure_redirect_url || "",
        }
      : {
          type: "reconnect",
          accountId: data.reconnect_account,
          // hosted controller return account type GOOGLE_OAUTH instead of GOOGLE
          accountType:
            data.account_type === ("GOOGLE_OAUTH" as "GOOGLE") ? "GOOGLE" : data.account_type,
          successUrl: data.success_redirect_url || "",
          failureUrl: data.failure_redirect_url || "",
          ...(data.account_type === "OUTLOOK" &&
            data.accountInfo && {
              accountInfo: data.accountInfo.value.result.connection_params.mail.mail,
            }),
        }),
    ...(data.sync_limit && { syncLimit: data.sync_limit }),
  };
};

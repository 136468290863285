import {
  Box,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { useState } from "react";
import LinkedinProxySettings from "./LinkedinProxySettings";

const LinkedinProxy = () => {
  const [checked, setChecked] = useState(false);

  return (
    <Box sx={{ width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setChecked(e.target.checked);
            }}
          />
        }
        label="Use a proxy"
      />
      {checked && <LinkedinProxySettings />}
    </Box>
  );
};

export default LinkedinProxy;

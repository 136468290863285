import { authenticateAccount, getAccountStatus, solveCheckpoint } from "../../../api/login";
import {
  ArkoseCaptchaData,
  AuthenticateRequestBody,
  CheckpointRequestBody,
  ContractListData,
  LinkedinBasicAuthFormData,
  LinkedinCookieAuthFormData,
  WizardSteps,
} from "../../../type";
import { useState } from "react";
import { handleLinkedinProxyValues } from "../../utils";

export const useLinkedinWizard = () => {
  const [accountId, setAccountId] = useState<string>();
  const [step, setStep] = useState<WizardSteps>("LOGIN");
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [contractList, setContractList] = useState<ContractListData>();
  const [captchaData, setCaptchaData] = useState<ArkoseCaptchaData>();
  const [source2FA, setSource2FA] = useState<"SMS" | "APP">();

  const handleLogin = async (
    data: LinkedinBasicAuthFormData | LinkedinCookieAuthFormData,
    accountIdToReconnect?: string | null | undefined,
  ) => {
    if (isLoading) return;

    try {
      setError(null);
      setIsLoading(true);

      let body: AuthenticateRequestBody = {
        provider: "LINKEDIN",
        user_agent: navigator.userAgent,
        ...(data.domain && { proxy: handleLinkedinProxyValues(data) }),
        ...((!data.messages_sync || !data.chats_sync) && {
          sync_limit: {
            ...(!data.messages_sync && { messages: 0 }),
            ...(!data.chats_sync && { chats: 0 }),
          },
        }),
        ...("email" in data
          ? { username: data.email.trim(), password: data.password.trim() }
          : { access_token: data.li_at, ...(data.li_a && { premium_token: data.li_a }) }),
      };

      const response = await authenticateAccount({ body, accountIdToReconnect });
      setIsLoading(false);

      setAccountId(response.account_id);

      if (response.object === "AccountCreated" || response.object === "AccountReconnected")
        setStep("COMPLETED");
      else if (response.object === "Checkpoint" && response.checkpoint.type !== "QRCODE") {
        setStep(response.checkpoint.type);

        if (response.checkpoint.type === "2FA" && response.checkpoint.source) {
          setSource2FA(response.checkpoint.source);
        }

        if (response.checkpoint.type === "CAPTCHA") {
          setCaptchaData({
            public_key: response.checkpoint.public_key,
            data: response.checkpoint.data,
          });
        }

        if (response.checkpoint.type === "CONTRACT_CHOOSER") {
          setContractList(response.checkpoint.contract_options);
        }
      }
      return response;
    } catch (error) {
      setError(error instanceof Error ? error : new Error("Unexpected error"));
      setIsLoading(false);
    }
  };

  const handleSolveCheckpoint = async (body: CheckpointRequestBody) => {
    if (isLoading) return;

    try {
      if (error) setError(null);
      setIsLoading(true);
      const response = await solveCheckpoint(body);
      setIsLoading(false);

      if (response.object === "AccountCreated" || response.object === "AccountReconnected")
        setStep("COMPLETED");
      else if (response.object === "Checkpoint" && response.checkpoint.type !== "QRCODE") {
        setStep(response.checkpoint.type);

        if (response.checkpoint.type === "CAPTCHA") {
          setCaptchaData({
            public_key: response.checkpoint.public_key,
            data: response.checkpoint.data,
          });
        }

        if (response.checkpoint.type === "CONTRACT_CHOOSER") {
          setContractList(response.checkpoint.contract_options);
        }
      }
      return response;
    } catch (error) {
      if (step === "CAPTCHA") setStep("LOGIN");
      setError(error instanceof Error ? error : new Error("Unexpected error"));
      setIsLoading(false);
      throw error;
    }
  };

  const handleWatchAccountStatus = async (accountIdToReconnect?: string | null | undefined) => {
    if (isLoading || !accountId) return;

    try {
      if (error) setError(null);
      setIsLoading(true);
      await Promise.race([
        getAccountStatus(accountId, accountIdToReconnect),
        new Promise((resolve, reject) => setTimeout(reject, 1000 * 60 * 10)), // 10 minutes timeout
      ]);
      setIsLoading(false);
      setStep("COMPLETED");
    } catch (error) {
      setIsLoading(false);
      setStep("FAIL");
    }
  };

  return {
    error,
    isLoading,
    step,
    accountId,
    handleSolveCheckpoint,
    handleWatchAccountStatus,
    handleLogin,
    captchaData,
    contractList,
    source2FA,
  };
};

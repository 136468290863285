import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AppContext, AppContextType } from "../../../../App";
import { useContext, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  LinkedinBasicAuthFormData,
  LinkedinCookieAuthFormData,
  LoginStepProps,
} from "../../../../type";
import { LoginOptions } from "../../../common/LoginOptions";
import SubmitButtons from "../../../common/SubmitButtons";
import SyncLimitCheckbox from "../../../common/SyncLimitCheckbox";
import { TabPanel } from "../../../common/TabPanel";
import { displayErrorMessage } from "../../../utils";
import LinkedinProxy from "./proxy/LinkedinProxy";

const LinkedinLoginContainer = (
  props: LoginStepProps<LinkedinBasicAuthFormData | LinkedinCookieAuthFormData>,
) => {
  const [tabs, setTabs] = useState(0);

  return (
    <Stack p={6} spacing={3} alignItems="center">
      <Typography variant="h6">Choose a method</Typography>
      <Tabs
        value={tabs}
        onChange={(_, newValue) => setTabs(newValue)}
        aria-label="Login methods"
      >
        <Tab label="Credentials" />
        <Tab label="Cookies" />
      </Tabs>
      <TabPanel sx={{ width: "100%" }} value={tabs} index={0}>
        <AuthWithCredentialsForm {...props} />
      </TabPanel>
      <TabPanel sx={{ width: "100%" }} value={tabs} index={1}>
        <AuthWithCookiesForm {...props} />
      </TabPanel>
    </Stack>
  );
};

const AuthWithCredentialsForm: React.FC<
  LoginStepProps<LinkedinBasicAuthFormData | LinkedinCookieAuthFormData>
> = ({ error, isLoading, onSubmit }) => {
  const { defaultSyncLimit } = useContext(AppContext) as AppContextType;

  const form = useForm<LinkedinBasicAuthFormData>({
    defaultValues: {
      chats_sync: defaultSyncLimit?.MESSAGING?.chats !== 0,
      messages_sync: defaultSyncLimit?.MESSAGING?.messages !== 0,
    },
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
        <Stack spacing={4}>
          <Controller
            name="email"
            control={form.control}
            defaultValue=""
            rules={{ required: "Email is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="email"
                placeholder="Email"
                value={value}
                onChange={onChange}
                fullWidth
                error={!!form.formState.errors.email}
                helperText={form.formState.errors.email?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="password"
            control={form.control}
            defaultValue=""
            rules={{ required: "Password is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="password"
                placeholder="Password"
                type="password"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
                error={!!form.formState.errors.password}
                helperText={form.formState.errors.password?.message}
              />
            )}
          />
          <LoginOptions label="Optional settings">
            <SyncLimitCheckbox
              name="chats_sync"
              label="Synchronize chats"
              TooltipProps={{
                title: "Choose if the chat history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
            <SyncLimitCheckbox
              name="messages_sync"
              label="Synchronize messages"
              TooltipProps={{
                title: "Choose if the messages history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
            <LinkedinProxy />
          </LoginOptions>

          {error && (
            <Alert severity="error" variant="filled">
              {displayErrorMessage(error)}
            </Alert>
          )}
          <SubmitButtons submitText="Login" isLoading={isLoading} />
        </Stack>
      </form>
    </FormProvider>
  );
};

const AuthWithCookiesForm: React.FC<
  LoginStepProps<LinkedinBasicAuthFormData | LinkedinCookieAuthFormData>
> = ({ error, isLoading, onSubmit }) => {
  const { defaultSyncLimit } = useContext(AppContext) as AppContextType;

  const form = useForm<LinkedinCookieAuthFormData>({
    defaultValues: {
      chats_sync: defaultSyncLimit?.MESSAGING?.chats !== 0,
      messages_sync: defaultSyncLimit?.MESSAGING?.messages !== 0,
    },
  });

  return (
    <FormProvider {...form}>
      <InstructionsDialog />
      <Typography variant="body2" marginBottom={2}>
        Your cookies need to be collected in the same browser as this page.
      </Typography>
      <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
        <Stack spacing={4}>
          <Controller
            name="li_at"
            control={form.control}
            rules={{ required: "li_at is required" }}
            defaultValue=""
            render={({ onChange, value, ...props }) => (
              <TextField
                id="li_at"
                placeholder="Enter you li_at value"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
                error={!!form.formState.errors.li_at}
                helperText={form.formState.errors.li_at?.message}
              />
            )}
          />

          <Typography variant="body2" marginBottom={2}>
            If your account has Recruiter or Sales Navigator subscription, copy
            the li_a too.
          </Typography>

          <Controller
            name="li_a"
            control={form.control}
            defaultValue=""
            render={({ onChange, value, ...props }) => (
              <TextField
                id="li_a"
                placeholder="Enter you li_a value (optional)"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
                error={!!form.formState.errors.li_a}
                helperText={form.formState.errors.li_a?.message}
              />
            )}
          />

          <LoginOptions label="Optional settings">
            <SyncLimitCheckbox
              name="chats_sync"
              label="Synchronize chats"
              TooltipProps={{
                title: "Choose if the chat history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
            <SyncLimitCheckbox
              name="messages_sync"
              label="Synchronize messages"
              TooltipProps={{
                title: "Choose if the messages history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
            <LinkedinProxy />
          </LoginOptions>

          {error && (
            <Alert severity="error" variant="filled">
              {displayErrorMessage(error)}
            </Alert>
          )}
          <SubmitButtons submitText="Login" isLoading={isLoading} />
        </Stack>
      </form>
    </FormProvider>
  );
};

function InstructionsDialog() {
  const [open, setOpen] = useState(false);
  return (
    <Box mb={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1" fontWeight="medium">
          Copy your LinkedIn cookies.
        </Typography>
        <Link
          component="button"
          type="button"
          underline="always"
          onClick={() => setOpen(true)}
        >
          How to find them?
        </Link>
      </Stack>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <DialogTitle>How to find my cookies ?</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              Follow the steps to find your linkedin cookies (not available on
              mobile)
            </Typography>

            <Typography variant="body2">
              1. Open linkedin in a new tab (or click here:{" "}
              <Link href="https://www.linkedin.com" target="_blank">
                linkedin
              </Link>
              ).
            </Typography>

            <Typography variant="body2">2. Log in to your account.</Typography>

            <Typography variant="body2">
              3. Open your browser's developer console (F12 for Chrome and
              Firefox, option + command + I for Safari) then go to the
              "application" or "storage" tab.
            </Typography>

            <Typography variant="body2">
              4.Open the cookies folder and click on the one called
              "https://www.linkedin.com".
            </Typography>

            <Typography variant="body2">
              5. Copy the values for "li_at" into the field below, then click on
              the connect button
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default LinkedinLoginContainer;

import {
  Alert,
  Divider,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MailFullAuthFormData } from "../../../type";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { authenticateAccount } from "../../../api/login";
import { useMutation } from "@tanstack/react-query";
import SubmitButtons from "../../common/SubmitButtons";
import { displayErrorMessage } from "../../utils";
import { useContext } from "react";
import { AppContext, AppContextType } from "../../../App";

interface Props {
  defaultValues: Partial<MailFullAuthFormData>;
  onSuccess: () => void;
}

export default function MailFullForm(props: Props) {
  const { defaultSyncLimit } = useContext(AppContext) as AppContextType;
  const { control, ...form } = useForm<MailFullAuthFormData>({
    defaultValues: props.defaultValues,
  });

  const { isError, isLoading, error, mutate } = useMutation({
    mutationFn: authenticateAccount,
    onSuccess: props.onSuccess,
  });

  const handleSubmit = async (data: MailFullAuthFormData) => {
    mutate({
      body: {
        provider: "MAIL",
        ...data,
        smtp_user: data.imap_user,
        smtp_password: data.imap_password,
        imap_port: +data.imap_port,
        smtp_port: +data.smtp_port,
        ...(defaultSyncLimit?.MAILING && {
          sync_limit: defaultSyncLimit.MAILING,
        }),
      },
    });
  };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} style={{ width: "100%" }}>
      <Stack spacing={6}>
        <Stack spacing={4}>
          <Controller
            name="imap_user"
            control={control}
            defaultValue=""
            rules={{ required: "Email is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="imap_user"
                placeholder="Email"
                value={value}
                onChange={onChange}
                fullWidth
                error={!!form.formState.errors.imap_host}
                helperText={form.formState.errors.imap_host?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="imap_password"
            control={control}
            defaultValue=""
            rules={{ required: "Password is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="imap_password"
                placeholder="Password"
                type="password"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
                error={!!form.formState.errors.imap_password}
                helperText={form.formState.errors.imap_password?.message}
              />
            )}
          />
          <Alert severity="info">
            We need more informations about your provider, please fill the empty
            fields
          </Alert>
          <Controller
            name="imap_host"
            control={control}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="imap_host"
                placeholder=""
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Imap server host"
                variant="standard"
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />
          <Controller
            name="imap_port"
            control={control}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="imap_port"
                type="number"
                placeholder="85"
                label="Imap server port"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />
          <Controller
            name="imap_encryption"
            control={control}
            defaultValue="default"
            render={({ onChange, value, ...props }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel>Encryption protocol</InputLabel>
                <Select
                  labelId="imap_encryption"
                  id="imap_encryption"
                  value={value}
                  onChange={onChange}
                  {...props}
                  label="Encryption protocol"
                >
                  <MenuItem value="ssl">SSL</MenuItem>
                  <MenuItem value="tls">TLS</MenuItem>
                  <MenuItem value="sparttls">SPARTTLS</MenuItem>
                  <MenuItem value="default">DEFAULT</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="smtp_host"
            control={control}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="smtp_host"
                label="Smtp server host"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                placeholder=""
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />
          <Controller
            name="smtp_port"
            control={control}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="smtp_port"
                label="Smtp server port"
                variant="standard"
                type="number"
                placeholder="85"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />
        </Stack>
        {isError && (
          <Alert severity="error" variant="filled">
            {displayErrorMessage(error)}
          </Alert>
        )}
        <SubmitButtons isLoading={isLoading} />
      </Stack>
    </form>
  );
}

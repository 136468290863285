import { Alert, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { AppContext, AppContextType } from "../App";
import { setDefaultAxiosConfig } from "../api";
import { getTokenPayload } from "../api/home";
import { AccountTypeSelection } from "../components/AccountTypeSelection";
import Login from "../components/Login";
import {
  displayErrorMessage,
  isValidUrl,
  removeTrailingSlash,
} from "../components/utils";

const InvalidLink = () => (
  <Alert severity="error" variant="filled">
    Invalid link
  </Alert>
);

const Home = () => {
  const { encodedUrl } = useParams();
  const [activateQuery, setActivateQuery] = useState(false);
  const [parseUrlError, setParseUrlError] = useState<Error>();
  const {
    setDefaultSyncLimit,
    setReconnectValues,
    setSelectedProvider,
    selectedProvider,
  } = useContext(AppContext) as AppContextType;

  const { isError, isLoading, data, error } = useQuery<
    Awaited<ReturnType<typeof getTokenPayload>>,
    Error
  >(["getTokenPayload"], getTokenPayload, {
    enabled: !!activateQuery,
    onSuccess: (data) => {
      if (data.successUrl && isValidUrl(data.successUrl)) {
        sessionStorage.setItem("successUrl", data.successUrl);
      }
      if (data.failureUrl && isValidUrl(data.failureUrl)) {
        sessionStorage.setItem("failureUrl", data.failureUrl);
      }

      if (data.syncLimit) setDefaultSyncLimit(data.syncLimit);

      if (data.type === "create" && data.providers.length === 1) {
        setReconnectValues({
          isOneProvider: true,
        });
        setSelectedProvider(data.providers[0]);
        return;
      } else if (data.type === "reconnect") {
        setReconnectValues({
          accountId: data.accountId,
          isOneProvider: true,
        });
        setSelectedProvider(data.accountType);
      }

      // const handleStateValue = () => {
      //   if ("providers" in data) {
      //     setReconnectValues({
      //       isOneProvider: true,
      //     });
      //     setSelectedProvider(data.providers[0]);
      //     return;
      //   }

      //   setSelectedProvider(data.accountType);
      //   setReconnectValues({
      //     accountId: data.accountId,
      //     isOneProvider: true,
      //   });
      // };

      // if (
      //   data.type === "reconnect" ||
      //   (data.type === "create" &&
      //     "providers" in data &&
      //     data.providers.length === 1)
      // ) {
      //   handleStateValue();
      // }
    },
  });

  useEffect(() => {
    // setup axios pre configured instance with token and base url
    if (!encodedUrl) throw new Error("Token error");
    try {
      const { token, url } = JSON.parse(atob(decodeURIComponent(encodedUrl)));
      const urlTrailingSlash = removeTrailingSlash(url);
      setDefaultAxiosConfig(token, urlTrailingSlash);
      setActivateQuery(true);
    } catch (err) {
      if (err instanceof Error) setParseUrlError(err);
    }
  }, []);

  if (isError || parseUrlError) {
    return (
      <Alert severity="error" variant="filled">
        {displayErrorMessage(isError ? error : parseUrlError)}
      </Alert>
    );
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (
    ("providers" in data && !data.providers.length) ||
    ("accountId" in data && !data.accountId) ||
    ("accountType" in data && !data.accountType)
  ) {
    return <InvalidLink />;
  }

  return (
    <>
      <Helmet>
        <title>
          {data.type === "create" ? "Connect" : "Reconnect"} an account
        </title>
      </Helmet>
      {selectedProvider ? (
        <Login type={data.type} />
      ) : "providers" in data ? (
        <AccountTypeSelection
          failureUrl={data.failureUrl}
          providers={data.providers}
          type={data.type}
        />
      ) : (
        <InvalidLink />
      )}
    </>
  );
};

export default Home;
